<template>
    <vs-chip class="ag-grid-cell-chip" :color="chipColor" v-if="chipColor">
      {{value}}
    </vs-chip>
    <span v-else>{{value}}</span>
</template>

<script>
export default {
  name: 'CellRendererStatus',
  computed: {
    chipColor () {
      let data = this.params.data;

      if (data.checked)
        return 'primary';
      else if (!data.active)
        return 'danger';
      else
        return 'success';
    },
    value () {
      let data = this.params.data;

      if (data.checked)
        return 'checked';
      else if (!data.active)
        return 'expired';
      else
        return 'active';
    }
  }
}
</script>

<style lang="scss" scpoped>
.ag-grid-cell-chip {
  &.vs-chip-primary {
    background: rgba(var(--vs-primary),.15);
    color: rgba(var(--vs-primary),1) !important;
    font-weight: 500;
  }
  &.vs-chip-success {
    background: rgba(var(--vs-success),.15);
    color: rgba(var(--vs-success),1) !important;
    font-weight: 500;
  }
  &.vs-chip-warning {
    background: rgba(var(--vs-warning),.15);
    color: rgba(var(--vs-warning),1) !important;
    font-weight: 500;
  }
  &.vs-chip-danger {
    background: rgba(var(--vs-danger),.15);
    color: rgba(var(--vs-danger),1) !important;
    font-weight: 500;
  }
}
</style>
