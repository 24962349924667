<template>

  <div class="vx-card p-6">

    <!-- Header -->
    <div class="flex justify-between flex-nowrap items-end mb-5">
      <div>
        <h4 class="mb-2">Reminder Histories</h4>
      </div>
      <vs-button color="danger" type="filled" @click="confirmBulkDelete" :disabled="selectedItems.length < 1">
        <feather-icon icon="Trash2Icon" svgClasses="h-5 w-5 hover:text-danger cursor-pointer"/>
        <span class="ml-2 select-none">Bulk Delete {{ selectedItems.length > 0 ? `(${selectedItems.length})` : '' }}</span>
      </vs-button>
    </div>

    <!-- Header Action -->
    <div class="flex justify-between flex-wrap items-center">

      <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
      <vs-input class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4" v-model="searchQuery" @input="updateSearchQuery" placeholder="Search..." />
      <!-- <vs-button class="mb-4 md:mb-0" @click="gridApi.exportDataAsCsv()">Export as CSV</vs-button> -->

    </div>


    <!-- AgGrid Table -->
    <ag-grid-vue
      style="height: 300px;min-height: unset;"
      ref="agGridTable"
      :components="components"
      :gridOptions="gridOptions"
      class="ag-theme-material w-100 my-4 ag-grid-table"
      :columnDefs="columnDefs"
      :defaultColDef="defaultColDef"
      :rowData="itemsData"
      rowSelection="multiple"
      colResizeDefault="shift"
      :animateRows="true"
      :floatingFilter="true"
      :pagination="true"
      :paginationPageSize="paginationPageSize"
      :suppressPaginationPanel="true"
      :enableRtl="$vs.rtl"
      @selection-changed="onSelectionChanged"
    >
    </ag-grid-vue>

    <div class="vx-row">
      <div class="vx-col sm:w-1/2 w-full">
        <!-- ITEMS PER PAGE -->
        <vs-dropdown vs-trigger-click class="cursor-pointer">
          <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
            <span class="mr-2">{{ currentPage * paginationPageSize - (paginationPageSize - 1) }} - {{ itemsData.length - currentPage * paginationPageSize > 0 ? currentPage * paginationPageSize : itemsData.length }} of {{ itemsData.length }}</span>
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
          <vs-dropdown-menu>

            <vs-dropdown-item @click="gridApi.paginationSetPageSize(10)">
              <span>10</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="gridApi.paginationSetPageSize(20)">
              <span>20</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="gridApi.paginationSetPageSize(25)">
              <span>25</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="gridApi.paginationSetPageSize(30)">
              <span>30</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </div>

      <div class="vx-col sm:w-1/2 w-full">
        <!-- PAGINATION -->
        <vs-pagination
          :total="totalPages"
          :max="7"
          v-model="currentPage" />
      </div>
    </div>

  </div>

</template>

<script>
import {mapGetters} from 'vuex'
import { AgGridVue } from 'ag-grid-vue'
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import vSelect from 'vue-select'
import Datepicker from 'vuejs-datepicker'
import axios from 'axios';

// Cell Renderer
import CellRendererLink from './cell-renderer/CellRendererLink.vue'
import CellRendererStatus from './cell-renderer/CellRendererStatus.vue'
import CellRendererVerified from './cell-renderer/CellRendererVerified.vue'
import CellRendererActions from './cell-renderer/CellRendererActionsHistories.vue'
import CellRendererFile from './cell-renderer/CellRendererFile.vue'
import CellRendererRepeat from "@/views/pages/Reminder/cell-renderer/CellRendererRepeat";


export default {
  components: {
    AgGridVue,
    vSelect,
    Datepicker,

    // Cell Renderer
    CellRendererLink,
    CellRendererStatus,
    CellRendererVerified,
    CellRendererActions,
    CellRendererFile,
    CellRendererRepeat,

  },
  props: ['itemsData'],
  data () {
    return {

      inventories: [],
      repeat: false,
      selectedItems: [],
      repeats: [
        {
          value: 1,
          text: 'Every day',
        },
        {
          value: 2,
          text: 'Every week',
        },
        {
          value: 3,
          text: 'Every month',
        },
        {
          value: 4,
          text: 'Every year'
        }
      ],

      searchQuery: '',

      // AgGrid
      gridApi: null,
      gridOptions: {},
      defaultColDef: {
        sortable: true,
        resizable: true,
        suppressMenu: true
      },
      columnDefs: [
        {
          headerName: this.$i18n.t('Title'),
          field: 'memo',
          filter: true,
          width: 200,
          checkboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          headerCheckboxSelection: true,
          cellRendererFramework: 'CellRendererLink'
        },
        {
          headerName: this.$i18n.t('Description'),
          field: 'deskripsi',
          filter: true,
          width: 200,
        },
        {
          headerName: this.$i18n.t('Date'),
          field: 'tanggal_reminder',
          filter: true,
          width: 200,
        },
        {
          headerName: this.$i18n.t('DateUpdate'),
          field: 'tanggal_update',
          filter: true,
          width: 200,
        },
        {
          headerName: 'Action',
          width: 120,
          cellRendererFramework: 'CellRendererActions',
          cellClass: 'text-center',
          cellRendererParams: {
            deleteItem: this.deleteItem.bind(this),
          },
        }
      ],

      // Cell Renderer Components
      components: {
        CellRendererLink,
        CellRendererStatus,
        CellRendererVerified,
        CellRendererActions,
        CellRendererRepeat,
      }
    }
  },
  computed: {
    paginationPageSize () {
      if (this.gridApi) return this.gridApi.paginationGetPageSize()
      else return 10
    },
    totalPages () {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages()
      else return 0
    },
    currentPage: {
      get () {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1
        else return 1
      },
      set (val) {
        this.gridApi.paginationGoToPage(val - 1)
      }
    },
  },
  methods: {
    updateSearchQuery (val) {
      this.gridApi.setQuickFilter(val)
    },

    // run this method if there is an click event inside row
    onSelectionChanged() {
      const selectedRows = this.gridApi.getSelectedRows();

      this.selectedItems = selectedRows.map(data => data.id)
    },

    loadReminderHistories () {
      this.$store.dispatch('reminder/fetchHistories').catch(err => {
        if (!err.response) {
          // network error
          err.response = {
            data: {
              message: 'Server error'
            }
          };
        }

        this.$vs.notify({
          title:'Oops something error',
          text: err.response.data.message,
          color:'danger'})
      })
    },

    deleteItem(items) {
      axios.delete(`/reminders/histories?reminder_history_ids=[${items}]`).then(resp => {
        this.$swal({
          title: 'Successful',
          text: resp.data.message,
          icon: 'success'
        }).then(() => {
          this.loadReminderHistories();
        });
      }).catch(err => {
        this.$vs.notify({
          title: 'Oops something error',
          text: err.data.message,
          color: 'danger'
        })
      })
    },

    // confirm bulk delete reminder histories
    confirmBulkDelete(){
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirm Delete',
        text: `You want to delete all of this selected item ?`,
        accept: this.deleteRecords,
        acceptText: 'Delete'
      })
    },

    deleteRecords(){
      this.deleteItem(this.selectedItems)
    }
  },
  mounted () {
    this.gridApi = this.gridOptions.api
    if (this.$vs.rtl) {
      const header = this.$refs.agGridTable.$el.querySelector('.ag-header-container')
      header.style.left = `-${  String(Number(header.style.transform.slice(11, -3)) + 9)  }px`
    }
  }
}

</script>

<style lang="scss">
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}

.vs-popup--content {
  overflow: visible;
}

.vs-button--text{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
