<template>
    <div :style="{'direction': $vs.rtl ? 'rtl' : 'ltr'}">
      <div v-if="!params.data.checked">
        <feather-icon icon="CheckSquareIcon" svgClasses="h-5 w-5 mr-4 text-primary cursor-pointer" @click="checklist" />
        <feather-icon icon="EditIcon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="editRecord" />
        <feather-icon icon="Trash2Icon" svgClasses="h-5 w-5 hover:text-danger cursor-pointer" @click="confirmDeleteRecord" />
      </div>
      <div v-else>
        <feather-icon icon="RefreshCwIcon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="repeat" />
        <feather-icon icon="Trash2Icon" svgClasses="h-5 w-5 hover:text-danger cursor-pointer" @click="confirmDeleteRecord" />
      </div>
    </div>
</template>

<script>
export default {
  name: 'CellRendererActions',
  computed: {
    encrypt() {
      let id = this.params.data.id;
      return this.$secure.encrypt(id);;
    }
  },
  methods: {
    showData () {
      this.$router.push({name: 'reminder-show', params: { id: this.encrypt }});
    },
    editRecord () {
      this.params.edit(this.params.data);
    },
    repeat () {
      this.params.repeat(this.params.data);
    },
    checklist () {
      this.params.checklist(this.params.data);
    },
    confirmDeleteRecord () {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirm Delete',
        text: `Are you sure delete it ?`,
        accept: this.deleteRecord,
        acceptText: 'Delete'
      })
    },
    deleteRecord () {
      this.$store.dispatch("reminder/removeItem", {id: this.params.data.id})
        .then((resp)   => {
          this.$store.dispatch('reminder/fetchItems').catch(err => {
            this.$vs.notify({
              title:'Opps something error',
              text: err.data.message,
              color:'danger'
            })
          })

          this.showDeleteSuccess(resp)
        })
        .catch(err => {
          this.showDeleteError(err)
        })
    },
    showDeleteSuccess (resp) {
      this.$vs.notify({
        color: 'success',
        title: 'Delete success',
        text: resp.data.message
      })
    },
    showDeleteError (err) {
      this.$vs.notify({
        title:'Opps something error',
        text: err.data.message,
        color:'danger'
      })
    }
  }
}
</script>
