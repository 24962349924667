<template>

  <div id="page-user-list" v-show="loaded">

    <div class="vx-row">

      <!-- List Reminder -->
      <div class="vx-col w-full lg:w-1/1 mb-6">
        <div class="vx-card p-6">

          <div class="flex justify-between flex-wrap items-end mb-5">

            <div>
              <h4 class="mb-2">List Reminder</h4>
            </div>

          </div>

          <!-- Header Action -->
          <div class="flex justify-between flex-wrap items-center">

            <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
            <vs-input class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4" v-model="searchQuery" @input="updateSearchQuery" placeholder="Search..." />
            <!-- <vs-button class="mb-4 md:mb-0" @click="gridApi.exportDataAsCsv()">Export as CSV</vs-button> -->

            <vs-button color="primary" icon-pack="feather" icon="icon-plus" class="mr-4" @click="add">Add Item</vs-button>

          </div>


          <!-- AgGrid Table -->
          <ag-grid-vue
            ref="agGridTable"
            :components="components"
            :gridOptions="gridOptions"
            class="ag-theme-material w-100 my-4 ag-grid-table"
            :columnDefs="columnDefs"
            :defaultColDef="defaultColDef"
            :rowData="itemsData"
            rowSelection="multiple"
            colResizeDefault="shift"
            :animateRows="true"
            :floatingFilter="false"
            :pagination="true"
            :paginationPageSize="paginationPageSize"
            :suppressPaginationPanel="true"
            :enableRtl="$vs.rtl">
          </ag-grid-vue>

          <div class="vx-row">
            <div class="vx-col sm:w-1/2 w-full">
              <!-- ITEMS PER PAGE -->
              <vs-dropdown vs-trigger-click class="cursor-pointer">
                <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                  <span class="mr-2">{{ currentPage * paginationPageSize - (paginationPageSize - 1) }} - {{ itemsData.length - currentPage * paginationPageSize > 0 ? currentPage * paginationPageSize : itemsData.length }} of {{ itemsData.length }}</span>
                  <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                </div>
                <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
                <vs-dropdown-menu>

                  <vs-dropdown-item @click="gridApi.paginationSetPageSize(10)">
                    <span>10</span>
                  </vs-dropdown-item>
                  <vs-dropdown-item @click="gridApi.paginationSetPageSize(20)">
                    <span>20</span>
                  </vs-dropdown-item>
                  <vs-dropdown-item @click="gridApi.paginationSetPageSize(25)">
                    <span>25</span>
                  </vs-dropdown-item>
                  <vs-dropdown-item @click="gridApi.paginationSetPageSize(30)">
                    <span>30</span>
                  </vs-dropdown-item>
                </vs-dropdown-menu>
              </vs-dropdown>
            </div>

            <div class="vx-col sm:w-1/2 w-full">
              <!-- PAGINATION -->
              <vs-pagination
                :total="getTotalPage"
                :max="7"
                v-model="currentPage" />
            </div>
          </div>
          
        </div>
      </div>
    </div>
    
    <!--  Popup Form  -->
    <vs-popup classContent="popup-example" :title="title" :active.sync="popupReminder"> 
        
        <div v-if="!isRepeat && !isChecklist">
        <div class="vx-row">
          <div class="vx-col md:w-1/1 w-full">
            <vs-input class="w-full mb-3" :label="$i18n.t('Title')" v-model="form.memo" />
          </div>
        </div>

        <div class="vx-row">
          <div class="vx-col md:w-1/1 w-full">
            <label for="" class="vs-input--label">{{$i18n.t('Description')}}</label>
            <vs-textarea rows="6" class="w-full mb-3" v-model="form.deskripsi" />
          </div>
        </div>

        <vs-divider></vs-divider>

        <div class="vx-row">
          <div class="vx-col md:w-1/1 w-full">
            <small class="mb-1">Tujuan</small>
            <v-select multiple :label="$i18n.t('Purpose')" v-model="form.tujuan" :options="['sales.admin@argasolusi.com', 'finance@argasolusi.com', 'admin.support@argasolusi.com']" />
            <!--          <vs-input class="w-full mb-3" :label="$i18n.t('Purpose')" v-model="form.tujuan" />-->
          </div>
        </div>

        <vs-divider></vs-divider>
      </div>

      <div v-if="isChecklist">
        <!-- Row Checklist -->
        <div class="vx-row mb-3">
          <div class="vx-col md:w-1/1 w-full">
            <vs-checkbox v-model="reminderCheck">{{ $i18n.t('SetReminder') }}</vs-checkbox>
          </div>
        </div>

        <vs-divider></vs-divider>
      </div>
      
      <div v-if="(isChecklist ? reminderCheck : true)">
        <!-- Row Date -->
        <div class="vx-row">
          <div class="vx-col md:w-1/2 w-full">
            <label class="vs-input--label">{{$i18n.t('Date')}}</label>
            <datepicker format="d/MM/yyyy" :value="form.tanggal" @input="form.tanggal = $moment($event)"></datepicker>
          </div>
          <div class="vx-col md:w-1/2 w-full">
            <label class="vs-input--label">{{$i18n.t('DateStartNotif')}}</label>
            <datepicker format="d/MM/yyyy" :value="form.tanggal_mulai_notif" @input="form.tanggal_mulai_notif = $moment($event)"></datepicker>
          </div>
        </div>
      </div>
      
      <div v-if="isChecklist ? reminderCheck : true">
        <vs-divider></vs-divider>

        <!-- Row Repeat Check -->
        <div class="vx-row mb-3">
          <div class="vx-col md:w-1/1 w-full">
            <vs-checkbox v-model="repeatCheck">{{ $i18n.t('Repeat') }}</vs-checkbox>
          </div>
        </div>

        <!-- Row Repeat Form -->
        <div class="vx-row" v-if="repeatCheck">
          <div class="vx-col md:w-1/3 w-full">
            <vs-input type="number" class="w-full" :label="$i18n.t('Years')" v-model="form.repeat_years" />
          </div>
          <div class="vx-col md:w-1/3 w-full">
            <vs-input type="number" class="w-full" :label="$i18n.t('Months')" v-model="form.repeat_months" />
          </div>
          <div class="vx-col md:w-1/3 w-full">
            <vs-input type="number" class="w-full" :label="$i18n.t('Days')" v-model="form.repeat_days" />
          </div>
          <div class="vx-col md:w-1/1 w-full" v-if="[form.repeat_years, form.repeat_months, form.repeat_days].some(item => item > 0)">
            <small>
              <i>
                * system will set reminder each
                <span v-if="form.repeat_years > 0">{{ form.repeat_years }} year<span v-if="form.repeat_years > 1">s </span> </span>
                <span v-if="form.repeat_months > 0">{{ form.repeat_months }} month<span v-if="form.repeat_months > 1">s </span> </span>
                <span v-if="form.repeat_days > 0">{{ form.repeat_days }} day<span v-if="form.repeat_days > 1">s </span> </span>
                automatically
              </i>
            </small>
          </div>
        </div>
      </div>

      <div class="mt-3">
        <vs-button color="primary" type="filled" icon-pack="feather" icon="icon-refresh-cw" @click="doRepeat" class="mt-5" v-if="isRepeat">Repeat</vs-button>
        <vs-button color="primary" type="filled" icon-pack="feather" icon="icon-edit" @click="update" class="mt-5" v-else-if="isEdit">Update</vs-button>
        <vs-button color="primary" type="filled" icon-pack="feather" :icon="`icon-${reminderCheck ? 'refresh-cw' : 'check-circle'}`" @click="setReminder" class="mt-5" v-else-if="isChecklist">
          <span v-if="reminderCheck">{{$i18n.t('SetReminder')}}</span>
          <span v-else>{{$i18n.t('Finish')}}</span>
        </vs-button>
        <vs-button color="primary" type="filled" icon-pack="feather" icon="icon-save" @click="save" class="mt-5" v-else>Save</vs-button>
      </div>

    </vs-popup>

  </div>

</template>

<script>
import {mapGetters} from 'vuex'
import { AgGridVue } from 'ag-grid-vue'
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import vSelect from 'vue-select'
import Datepicker from 'vuejs-datepicker'

import ReminderHistory from "@/views/pages/Reminder/ReminderHistory";

// Store Module
import moduleUserManagement from '@/store/user-management/moduleUserManagement.js'

// Cell Renderer
import CellRendererLink from './cell-renderer/CellRendererLink.vue'
import CellRendererStatus from './cell-renderer/CellRendererStatus.vue'
import CellRendererVerified from './cell-renderer/CellRendererVerified.vue'
import CellRendererActions from './cell-renderer/CellRendererActions.vue'
import CellRendererFile from './cell-renderer/CellRendererFile.vue'
import CellRendererRepeat from "@/views/pages/Reminder/cell-renderer/CellRendererRepeat";


export default {
  components: {
    AgGridVue,
    vSelect,
    Datepicker,
    ReminderHistory,

    // Cell Renderer
    CellRendererLink,
    CellRendererStatus,
    CellRendererVerified,
    CellRendererActions,
    CellRendererFile,
    CellRendererRepeat,

  },
  data () {
    return {
      loaded: false,
      title: `Form ${this.$i18n.t('Reminder')}`,
      isRepeat: false,
      isEdit: false,
      isChecklist: false,
      popupReminder: false,
      form: {
        tujuan: ['sales.admin@argasolusi.com', 'finance@argasolusi.com', 'admin.support@argasolusi.com'],
        repeat_years: 0,
        repeat_months: 0,
        repeat_days: 0,
      },
      repeatCheck: false,
      reminderCheck: false,
      repeats: [
        {
          value: 1,
          text: 'Every day',
        },
        {
          value: 2,
          text: 'Every week',
        },
        {
          value: 3,
          text: 'Every month',
        },
        {
          value: 4,
          text: 'Every year'
        }
      ],

      searchQuery: '',

      // AgGrid
      gridApi: null,
      getTotalPage: 0,
      gridOptions: {
        pagination: true,
        onPaginationChanged: this.onPaginationChanged,
      },
      defaultColDef: {
        sortable: true,
        resizable: true,
        suppressMenu: true
      },
      columnDefs: [
        {
          headerName: this.$i18n.t('Title'),
          field: 'memo',
          filter: true,
          width: 300,
          cellRendererFramework: 'CellRendererLink'
        },
        {
          headerName: this.$i18n.t('Date'),
          field: 'tanggal',
          filter: true,
        },
        {
          headerName: this.$i18n.t('DateStartNotif'),
          field: 'tanggal_mulai_notif',
          filter: true,
        },
        {
          headerName: this.$i18n.t('Status'),
          cellRendererFramework: 'CellRendererStatus',
        },
        {
          headerName: 'Action',
          cellRendererFramework: 'CellRendererActions',
          headerClass: 'text-center',
          cellClass: 'text-center',
          cellRendererParams: {
            edit: this.edit.bind(this),
            repeat: this.repeat.bind(this),
            checklist: this.checklist.bind(this),
          }
        }
      ],

      // Cell Renderer Components
      components: {
        CellRendererLink,
        CellRendererStatus,
        CellRendererVerified,
        CellRendererActions,
        CellRendererRepeat,
      }
    }
  },
  computed: {
    itemsData () {
      // return this.$store.state.reminder.items.filter(i"tem => item.active)
      this.$store.state.reminder.items.sort((a, b) => a.active - b.active);
      return this.$store.state.reminder.items;
    },
    reminderHistories () {
      return this.$store.state.reminder.histories
    },
    paginationPageSize () {
      if (this.gridApi) return this.gridApi.paginationGetPageSize()
      else return 10
    },
    totalPages () {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages()
      else return 0
    },
    currentPage: {
      get () {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1
        else return 1
      },
      set (val) {
        this.gridApi.paginationGoToPage(val - 1)
      }
    },
  },
  watch: {
    itemsData(items){
      console.log(items.filter(item => !item.active && !item.checked))
    }
  },
  methods: {
    loading () {
      this.loaded = false;

      this.$vs.loading({
        type: 'border',
        color: 'primary',
        text: `Wait a minute, It's getting data.`
      })
    },
    
    onPaginationChanged(){
      return this.getTotalPage = this.gridOptions.api.paginationGetTotalPages()
    },

    updateSearchQuery (val) {
      this.gridApi.setQuickFilter(val)
    },
    loadReminders () {
      this.$store.dispatch('reminder/fetchItems').catch(err => {
        if (!err.response) {
          // network error
          err.response = {
            data: {
              message: 'Server error'
            }
          };
        }

        this.$vs.notify({
          title:'Opps something error',
          text: err.response.data.message,
          color:'danger'})
      })
      .finally(() => {
        this.onLoaded()
      })
    },
    loadReminderHistories () {
      this.$store.dispatch('reminder/fetchHistories').catch(err => {
        if (!err.response) {
          // network error
          err.response = {
            data: {
              message: 'Server error'
            }
          };
        }

        this.$vs.notify({
          title:'Opps something error',
          text: err.response.data.message,
          color:'danger'})
      })
    },
    dispatch (payload, address) {
      this.$store.dispatch(address, payload).then((response) => {
        this.popupReminder = false;
        this.$swal({
          title: 'Successful',
          text: response.data.message,
          icon: 'success'
        }).then(() => {
          this.loadReminders();
          this.loadReminderHistories();
        });
      }).catch(err => {
        console.log(err);
        if (!err.response) {
          // network error
          err.response = {
            data: {
              message: 'Server error'
            }
          };
        }
        this.$vs.notify({
          title:'Opps something error',
          text: err.response.data.message,
          color:'danger'})
      });
    },
    reset () {
      this.isRepeat = false;
      this.isEdit = false;
      this.isChecklist = false;
      this.popupReminder = false;
      this.form = {
      tujuan: ['sales.admin@argasolusi.com', 'finance@argasolusi.com', 'admin.support@argasolusi.com'],
          repeat_years: 0,
          repeat_months: 0,
          repeat_days: 0,
      };
      this.repeatCheck = false;
      this.reminderCheck = false;
    },
    add () {
      this.reset();
      this.title = this.$i18n.t('Add') + ' ' + this.$i18n.t('Reminder');
      this.popupReminder = true;
    },
    save () {
      let payload = {
        body: this.form
      }, address = "reminder/storeItem";

      this.dispatch(payload, address);
    },
    edit (item) {
      this.reset();
      this.title = this.$i18n.t('Edit') + ' ' + this.$i18n.t('Reminder');
      this.$store.dispatch('reminder/detailItem', { id: item.id }).then(resp => {
        this.form = resp.data.values;
        let hasRepeat = [this.form.repeat_days, this.form.repeat_months, this.form.repeat_years].some(item => item > 0);
        if (hasRepeat) {
          this.repeat = true;
          this.repeatCheck = true;
        };
        this.form.tujuan = this.form.tujuan.split('|');
        this.isEdit = true;
        this.popupReminder = true;
      });
    },
    update () {
      let payload = {
        body: this.form
      }, address = "reminder/updateItem";

      // set ID
      payload.id = this.form.id;

      // set a put method
      payload.body._method = 'put';

      this.dispatch(payload, address);
    },
    repeat (item) {
      this.reset();
      this.title = this.$i18n.t('SetReminder');
      this.$store.dispatch('reminder/detailItem', { id: item.id }).then(resp => {
        this.form = resp.data.values;
        let hasRepeat = [this.form.repeat_days, this.form.repeat_months, this.form.repeat_years].some(item => item > 0);
        if (hasRepeat) {
          this.repeat = true;
        };
        this.form.tujuan = this.form.tujuan.split('|');
        this.isRepeat = true;
        this.popupReminder = true;
      });
    },
    doRepeat () {
      let payload = {
        body: this.form
      }, address = "reminder/updateItem";

      // set ID
      payload.id = this.form.id;

      // set a put method
      payload.body._method = 'put';

      // set unchecked status
      payload.body.checked = "0";

      this.dispatch(payload, address);
    },
    checklist (item) {
      this.reset();
      this.title = this.$i18n.t('Finish') + ' ' + this.$i18n.t('Reminder');
      this.$store.dispatch('reminder/detailItem', { id: item.id }).then(resp => {
        this.form = resp.data.values;
        let hasRepeat = [this.form.repeat_days, this.form.repeat_months, this.form.repeat_years].some(item => item > 0);
        if (hasRepeat) {
          this.repeat = true;
        }
        this.form.tujuan = this.form.tujuan.split('|');
        this.isChecklist = true;
        this.popupReminder = true;
      });
    },
    setReminder () {
      let payload = {
        body: this.form
      }, address = "reminder/updateItem";

      // set ID
      payload.id = this.form.id;

      // set a put method
      payload.body._method = 'put';

      // set unchecked status
      payload.body.checked = this.reminderCheck ? "0" : "1";

      // put set reminder
      payload.body.checklist = true;

      this.dispatch(payload, address);
    },

    onLoaded(){
      this.loaded = true;
      this.$vs.loading.close();
    }
  },
  mounted () {
    this.gridApi = this.gridOptions.api
    if (this.$vs.rtl) {
      const header = this.$refs.agGridTable.$el.querySelector('.ag-header-container')
      header.style.left = `-${  String(Number(header.style.transform.slice(11, -3)) + 9)  }px`
    }

    this.gridApi.sizeColumnsToFit()
  },
  created () {
    this.loading();
    this.loadReminders();
    this.loadReminderHistories();
    this.$store.dispatch('inventory/fetchItems').then(response => {
      this.inventories = response.data.values;
    });
  }
}

</script>

<style lang="scss">
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}

.vs-popup--content {
  max-height: 600px; /* Adjust the max-height according to your needs */
  overflow-y: auto;
}
</style>
